<template>
    <v-container :class="{'padding-container-top-small': $vuetify.breakpoint.smAndDown }">
        <v-layout wrap class="hidden-sm-and-down">
            <v-flex d-flex xs12 justify-center align-center class="page-top-margin">
                &nbsp;
            </v-flex>
        </v-layout>

        <v-layout row wrap align-center>
            <v-flex d-flex xs12 justify-center align-center>
            <v-card max-width="600px" :flat="$vuetify.breakpoint.smAndDown">
                <v-form ref="form">
                    <v-card-text style="padding-top: 0px">
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" :class="{'padding-input': $vuetify.breakpoint.smAndDown }">
                                    <v-text-field label="First name" v-model="firstName" :rules="inputRules">
                                      <v-icon slot="prepend" color="primary lighten-1">mdi-account</v-icon>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" :class="{'padding-input': $vuetify.breakpoint.smAndDown }">
                                    <v-text-field label="Last name" v-model="lastName" prepend-icon="mdi-empty" :rules="inputRules"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" :class="{'padding-input': $vuetify.breakpoint.smAndDown }">
                                    <v-text-field label="Phone" v-model="phone" v-mask="phoneMask" :rules="phoneRules">
                                      <v-icon slot="prepend" color="primary lighten-1">mdi-phone</v-icon>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="8" :class="{'padding-input': $vuetify.breakpoint.smAndDown }">
                                    <v-text-field label="Email" v-model="email" :rules="emailRules">
                                      <v-icon slot="prepend" color="primary lighten-1">mdi-email</v-icon>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="5" :class="{'padding-input': $vuetify.breakpoint.smAndDown }">
                                    <v-menu v-model="moveInDateMenu" :close-on-content-click="false" full-width>
                                        <template v-slot:activator="{ on }">
                                            <v-text-field :value="formattedMoveInDate" clearable label="Desired Move-in" readonly v-on="on" :rules="inputRules">
                                              <v-icon slot="prepend" color="primary lighten-1">mdi-calendar</v-icon>
                                            </v-text-field>
                                        </template>
                                    <v-date-picker v-model="moveInDate" @change="moveInDateMenu = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" :class="{'padding-input': $vuetify.breakpoint.smAndDown }">
                                    <v-textarea name="notes" label="Notes" v-model="notes" style="margin-bottom: -40px;">
                                      <v-icon slot="prepend" color="primary lighten-1">mdi-message-text-outline</v-icon>
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                        
                    </v-card-text>

                    <v-card-actions :class="{ 'padding-action-small': $vuetify.breakpoint.smAndDown, 'padding-action': $vuetify.breakpoint.mdAndUp }">
                        <v-btn color="primary darken-1" text dark @click="cancel">Clear</v-btn>
                        <v-btn color="primary darken-1" text dark @click="close">Close</v-btn>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text dark @click="sendMessage" :loading="applyHereLoading">Apply</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { parseISO, format } from "date-fns";
import { mask } from "vue-the-mask";
import { MASK } from "@/constants/Masks";

export default {
  name: "ApplyHere",

  directives: {
    mask
  },

  data() {
    return {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      moveInDate: null,
      notes: "",
      inputRules: [v => (!!v && v.length > 0) || "Field cannot be empty"],
      phoneRules: [v => (!!v && v.length > 13) || "Phone must be valid"],
      emailRules: [
        v => !!v || "Email is required",
        v => /.+@.+\..+/.test(v) || "Email must be valid"
      ],
      moveInDateMenu: false,
      phoneMask: MASK.phone
    };
  },

  computed: {
    ...mapGetters(["applyHereLoading"]),
    formattedMoveInDate() {
      return this.moveInDate
        ? format(parseISO(this.moveInDate), "MMMM dd yyyy")
        : "";
    }
  },

  methods: {
    cancel() {
      this.$store.dispatch("openContactUsDialog", false);
      this.$refs.form.reset();
    },
    close() {
      this.$router.push({ name: "PropertyDetails" });
    },
    sendMessage() {
      if (this.$refs.form.validate()) {
        const form = {
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          email: this.email,
          moveInDate: this.moveInDate,
          notes: this.notes,
          createdDate: new Date()
        };

        this.$store.dispatch("submitApplyHere", form);
        this.$refs.form.reset();
      }
    },
    resetForm() {
      this.$refs.form.reset();
    }
  }
};
</script>

<style scoped>
.padding-container-top-small {
  padding-top: 0px;
}

.padding-input {
  padding: 0 10px;
}

.padding-action {
  padding: 5px 30px 20px 30px;
}

.padding-action-small {
  padding: 5px 20px 10px 30px;
}
</style>